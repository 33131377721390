import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import CebForm from "../../../components/Form/CebForm";
import mainApi from "../../../api/main";
import useApi from "../../../hooks/useApi";
import { Context } from "../../../context/Context";
import getConditionalObj from "../../../helpers/getConditionalObj";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Modal from "../../../components/UI/Modal";
import conditionalObj from "../../../helpers/getConditionalObj";
import useDiseasesField from "../../../hooks/useDiseasesFields";
import usePermissions from "../../../hooks/usePermissions";
import { modules } from "../../../config/constants";
import Print from "../../../components/Print";
import i18next, { t } from "i18next";

const module = modules.PAST_HISTORY;

export default function PastHistoryPage({ mode }) {
  const { patientId, age, gender } = useParams();
  // const { viewModeKey } = useContext(Context);
  const [viewMode, setViewMode] = useState("list");
  const [femaleViewMode, setFemaleViewMode] = useState("list");
  const [femaleDetailObj, setFemaleDetailObj] = useState({});
  const youngPatientDisplayCondition = age < 12;
  const adultFemaleDisplayCondition =
    age >= 12 && age <= 50 && gender === "female";

  const getDiseasesField = useDiseasesField();

  const [diseases, setDiseases] = useState([]);
  const [listData, setListData] = useState([]);
  const [watchedFields, setWatchedFields] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [chosenFields, setChosenFields] = useState([]);

  //modal
  const [openModal, setOpenModal] = useState(false);

  const relations = [
    "Father",
    "Mother",
    "Brother",
    "Sister",
    "Uncle",
    "Aunt",
    "Grandfather",
    "Grandmother",
  ];
  const specialHabitTypes = [
    "Smoking",
    "Alcohol",
    "Tramadol",
    "Marijwana",
    "Opiates",
    "Other",
  ];

  const allergyTypes = ["Food", "Drug", "General", "Other"];
  const orderBetweenSiblings = ["First", "Middle", "Last"];
  const deliveryTypes = [
    "Vaginal",
    "Natural",
    "Scheduled Cesarean",
    "Unplanned Cesarean",
    "Induction",
  ];
  const termsOfDelivery = ["Preterm", "Fullterm", "Postterm"];
  const contraceptionTypes = [
    "Pill",
    "Patch",
    "Injection",
    "Intrauterine",
    "VaginalRing",
  ];

  const getDiseases = useApi(mainApi.getDiseases);
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const addPastHistory = useApi(mainApi.addPastHistory);
  const getPastHistory = useApi(mainApi.getPastHistory);

  //permissions
  const permissions = usePermissions(module);

  useEffect(() => {
    if (permissions?.view) {
      getPastHistory.request(patientId);
    }
  }, [addPastHistory.data, permissions]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const youngerThanTwelveObj = ({
    sibling_order,
    delivery_type,
    obstructed_labor,
    term_delivery,
    prom,
    neonatal_sepsis,
    nicu,
    nicu_details,
    jaundice_history,
    maternal_illness,
    maternal_illness_details,
    maternal_drugs,
    maternal_drugs_details,
    maternal_vaccination,
    maternal_vaccination_details,
    folate_intake,
    sibling_similar_diseases,
  }) => {
    return {
      sibling_order,
      delivery_type,
      obstructed_labor: obstructed_labor[0],
      term_delivery,
      prom: prom[0],
      neonatal_sepsis: neonatal_sepsis[0],
      nicu: nicu[0],
      nicu_details,
      jaundice_history: jaundice_history[0],
      maternal_illness: maternal_illness[0],
      maternal_illness_details,
      maternal_drugs: maternal_drugs[0],
      maternal_drugs_details,
      maternal_vaccination: maternal_vaccination[0],
      maternal_vaccination_details,
      folate_intake: folate_intake[0],
      sibling_similar_diseases: sibling_similar_diseases?.map(
        (item) => item.siblingDisease.id
      ),
    };
  };

  //adult female

  const adultFemaleObj = ({
    num_pregnancies,
    date_last_pregnancy,
    date_menarche,
    date_lmp,
    num_menses_days,
    interval_cycles,
    num_tampons_per_day,
    date_menopause,
    previous_pregnancy,
    num_full_term_deliveries,
    date_last_full_term_delivery,
    num_pre_term_deliveries,
    date_last_pre_term_delivery,
    num_post_term_deliveries,
    date_last_post_term_delivery,
    num_abortions,
    date_last_abortion,
    num_living_fetus,
    is_contraceptive,
    contraception_type,
    contraception_date,
    date_birth_youngest,
    num_boys,
    num_girls,
    antepartums,
    babies,
    delivery_places,
    purperiums,
    complications,
    mode_prev_deliveries,
    onset_prev_deliveries,
  }) => {
    let obj = {
      date_menarche,
      date_lmp,
      num_menses_days,
      interval_cycles,
      num_tampons_per_day,
      date_menopause,
      is_contraceptive: is_contraceptive[0],
      contraception_type,
      contraception_date,
    };
    let pregnancy = {
      onset_prev_deliveries,
      mode_prev_deliveries,
      complications,
      purperiums,
      delivery_places,
      babies,
      antepartums,
      num_girls,
      num_boys,
      date_birth_youngest,
      num_pregnancies,
      date_last_pregnancy,
      num_full_term_deliveries,
      date_last_full_term_delivery,
      num_pre_term_deliveries,
      date_last_pre_term_delivery,
      num_post_term_deliveries,
      date_last_post_term_delivery,
      num_abortions,
      date_last_abortion,
      num_living_fetus,
    };

    if (watchedFields[11] && watchedFields[11][0]) return { ...obj, pregnancy };
    else return obj;
  };

  const generalFormObj = (data) => {
    return {
      family_histories: data.family_histories.map((item) => ({
        ...item,
        disease: item.disease.id,
      })),
      special_habits: data.special_habits,
      surgeries: data.surgeries,
      medications: data.medications,
      allergies: data.allergies,
      diseases: data.diseases.map((disease) => ({
        disease: disease.disease.id,
        is_active: disease.is_active,
      })),
    };
  };

  const checkEmptyObj = (obj) => {
    let result = true;
    for (const key in obj) {
      if (
        obj[key] !== null &&
        obj[key] !== "" &&
        obj[key] !== undefined &&
        obj[key] !== false &&
        obj[key].length > 0
      ) {
        result = false;
        break;
      }
    }
    return result;
  };

  // const onSubmit = async (data) => {
  //   let obj = null;
  //   if (!checkEmptyObj(generalFormObj(data)))
  //     obj = { general_form: generalFormObj(data) };
  //   if (
  //     youngPatientDisplayCondition &&
  //     !checkEmptyObj(youngerThanTwelveObj(data))
  //   )
  //     obj = { young_patient: youngerThanTwelveObj(data), ...obj };
  //   if (adultFemaleDisplayCondition && !checkEmptyObj(adultFemaleObj(data)))
  //     obj = { adult_female: adultFemaleObj(data), ...obj };
  //   let res = await addPastHistory.requestWithToast(
  //     "Data saved successfully",
  //     obj,
  //     patientId
  //   );
  //   console.log("obj", obj);
  //   if (res?.ok) {
  //     getPastHistory.request(patientId);
  //     setViewMode("list");
  //     setResetForm(true);
  //   }
  // };

  const onSubmit = async (data) => {
    let obj = null;
    console.log("data", data);
    if (mode === "general") obj = { general_form: generalFormObj(data) };
    else if (mode === "young")
      obj = { young_patient: youngerThanTwelveObj(data) };
    else if (mode === "female") obj = { adult_female: adultFemaleObj(data) };

    let res = await addPastHistory.requestWithToast(
      t("Saved successfully"),
      obj,
      patientId
    );
    console.log("obj", obj);
    if (res?.ok) {
      // getPastHistory.request(patientId);
      setViewMode("list");
      setResetForm(true);
    }
  };

  useEffect(() => {
    switch (mode) {
      case "female":
        setChosenFields(femaleFields);
        break;
      case "young":
        setChosenFields(youngFields);
        break;
      default:
        setChosenFields(fields);
        break;
    }
  }, [mode, watchedFields, getDiseasesField.diseases]);

  const youngFields = [
    {
      isEnum: true,
      items: orderBetweenSiblings,
      type: "select",
      label: "Order between sibilings",
      name: "sibling_order",
      width: 300,
    },
    {
      fieldType: "nested",
      nestedId: 7,
      title: "Disease",
      condition: [true],
      name: "sibling_similar_diseases",
      // data: siblingFamilyHistories,
      // onAdd: handleAddSiblingFamilyHistory,
      // onDelete: (index) =>
      //   setSiblingFamilyHistories((oldArr) => removeArrItem(oldArr, index)),
      primaryListText: {
        key: "Disease",
        value: "field.siblingDisease?.label",
      },
      fields: [
        {
          autoComplete: true,
          onInputChange: getDiseasesField.handleInputChange,
          items: getDiseasesField.diseases,
          type: "select",
          label: "Disease",
          name: "siblingDisease",
          width: 300,
          disableFilter: true,
        },
      ],
    },
    {
      isEnum: true,
      items: deliveryTypes,
      type: "select",
      label: "Type of delivery",
      name: "delivery_type",
      width: 300,
    },
    {
      type: "boolean",
      label: "Obstructed labor",
      name: "obstructed_labor",
    },
    {
      isEnum: true,
      items: termsOfDelivery,
      type: "select",
      label: "Term of delivery",
      name: "term_delivery",
      width: 300,
    },
    { type: "boolean", label: "PROM", name: "prom" },
    {
      type: "boolean",
      label: "Neonatal Sepsis",
      name: "neonatal_sepsis",
    },
    {
      twoFieldsInRow: true,
      fields: [
        { type: "boolean", label: "NICU", name: "nicu" },
        {
          condition: watchedFields[7],
          type: "number",
          label: "Days",
          name: "nicu_details",
          width: 160,
          validation: { positive: true },
        },
      ],
    },
    {
      type: "boolean",
      label: "History of jaudince",
      name: "jaundice_history",
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          type: "boolean",
          label: "Maternal Illness",
          name: "maternal_illness",
        },
        {
          condition: watchedFields[8],
          label: "Mention",
          name: "maternal_illness_details",
          width: 160,
        },
      ],
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          type: "boolean",
          label: "Maternal Drugs",
          name: "maternal_drugs",
        },
        {
          condition: watchedFields[9],
          label: "Mention",
          name: "maternal_drugs_details",
          width: 160,
        },
      ],
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          type: "boolean",
          label: "Maternal Vaccination",
          name: "maternal_vaccination",
        },
        {
          condition: watchedFields[10],
          label: "Mention",
          name: "maternal_vaccination_details",
          width: 160,
        },
      ],
    },
    {
      type: "boolean",
      label: "Folate intake",
      name: "folate_intake",
    },
  ];

  const femaleFields = [
    {
      type: "date",
      label: "Date of menarche",
      name: "date_menarche",
      // width: 300,
    },
    {
      type: "date",
      label: "Date of LMP",
      name: "date_lmp",
      // width: 300,
    },
    {
      label: "Number of days of menses",
      name: "num_menses_days",
      type: "number",
      validation: { positive: true },
      // width: 300,
    },
    {
      label: "Interval between each cycle",
      name: "interval_cycles",
      type: "number",
      validation: { positive: true },
      // width: 300,
    },
    {
      label: "Number of Meneses Pads/Day",
      name: "num_tampons_per_day",
      type: "number",
      validation: { positive: true },
      // width: 300,
    },
    {
      type: "date",
      label: "Date of menopause",
      name: "date_menopause",
      // width: 300,
    },
    {
      type: "boolean",
      label: "Contraception",
      name: "is_contraceptive",
    },
    {
      childField: true,
      type: "date",
      condition: watchedFields[12],
      label: "Since",
      name: "contraception_date",
      // width: 300,
    },
    {
      childField: true,
      isEnum: true,
      condition: watchedFields[12],
      items: contraceptionTypes,
      type: "select",
      label: "Type of contraception",
      name: "contraception_type",
      // width: 300,
    },
    {
      type: "boolean",
      label: "Pregnancies",
      name: "previous_pregnancy",
    },
    conditionalObj(watchedFields[11] && watchedFields[11][0], {
      relatedFields: true,
      addBorder: false,
      displayCondition: watchedFields[11] && watchedFields[11][0],
      fields: [
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of pregnancies",
              name: "num_pregnancies",
              type: "number",
              validation: { required: true, positive: true },
              width: 160,
            },
            {
              childField: true,
              label: "Date of the Last pregnancy",
              name: "date_last_pregnancy",
              type: "date",
              validation: { required: true },
              width: 160,
            },
          ],
        },
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of Living Fetus",
              name: "num_living_fetus",
              type: "number",
              validation: { required: true, positive: true },
              width: 160,
            },
            {
              childField: true,
              label: "Date of birth of youngest",
              name: "date_birth_youngest",
              type: "date",
              validation: { required: true },
              width: 160,
            },
          ],
        },
        {
          childField: true,
          label: "Number of Boys",
          name: "num_boys",
          type: "number",
          validation: { required: true, positive: true },
          width: 160,
        },
        {
          childField: true,
          label: "Number of Girls",
          name: "num_girls",
          type: "number",
          validation: { required: true, positive: true },
          width: 160,
        },
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of full term deliveries",
              name: "num_full_term_deliveries",
              type: "number",
              validation: { required: true, positive: true },
              width: 160,
            },
            {
              childField: true,
              label: "Date of the last full term Delivery",
              name: "date_last_full_term_delivery",
              type: "date",
              validation: watchedFields[17] > 0 && { required: true },
              width: 160,
            },
          ],
        },
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of pre-term deliveries",
              name: "num_pre_term_deliveries",
              type: "number",
              validation: { required: true, positive: true },
              width: 160,
            },
            {
              childField: true,
              label: "Date of the last pre-term Delivery",
              name: "date_last_pre_term_delivery",
              type: "date",
              validation: watchedFields[18] > 0 && { required: true },
              width: 160,
            },
          ],
        },
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of post term deliveries",
              name: "num_post_term_deliveries",
              type: "number",
              validation: watchedFields[17] && {
                required: true,
                positive: true,
              },
              width: 160,
            },
            {
              childField: true,
              label: "Date of the last post term Delivery",
              name: "date_last_post_term_delivery",
              type: "date",
              validation: watchedFields[19] > 0 && { required: true },
              width: 160,
            },
          ],
        },
        {
          twoFieldsInRow: true,
          fields: [
            {
              childField: true,
              label: "Number of abortions",
              name: "num_abortions",
              validation: { required: true, positive: true },
              type: "number",
              width: 160,
            },
            {
              childField: true,
              label: "Date of the last abortion",
              name: "date_last_abortion",
              validation: watchedFields[20] > 0 && { required: true },
              type: "date",
              width: 160,
            },
          ],
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Onset of previous delivery",
          name: "onset_prev_deliveries",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Mode of previous delivery",
          name: "mode_prev_deliveries",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Baby",
          name: "babies",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Antepartum Period",
          name: "antepartums",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Complications of previous deliveries",
          name: "complications",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Places of previous deliveries",
          name: "delivery_places",
          width: 300,
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Puperium",
          name: "purperiums",
          width: 300,
        },
      ],
    }),
  ];

  const fields = [
    // { multipleRecords: true, label: "Medications", name: "medication_list" },
    {
      fieldType: "nested",
      nestedId: 1,
      title: "Medications",
      name: "medications",
      primaryListText: { key: "Medication", value: "field.medication" },
      secondaryListText: {
        key: "Status",
        value: "field.is_active ? 'Active':'Inactive' ",
      },
      fields: [
        {
          childField: true,
          label: "Medication",
          validation: { required: true },
          name: "medication",
        },
        {
          childField: true,
          label: "Active",
          name: "is_active",
          type: "boolean",
          width: 300,
        },
      ],
    },
    {
      fieldType: "nested",
      nestedId: 2,
      title: "Surgical History",
      name: "surgeries",
      primaryListText: { key: "Name", value: "field.label" },
      secondaryListText: {
        key: "Date",
        value: "field.date",
      },
      fields: [
        {
          childField: true,
          isEnum: true,
          label: "Name",
          validation: { required: true },
          name: "label",
          // width: 300,
        },
        {
          childField: true,
          label: "Date",
          name: "date",
          type: "date",
          // width: 300,
        },
      ],
    },
    {
      fieldType: "nested",
      nestedId: 3,
      title: "Diseases",
      name: "diseases",
      primaryListText: { key: "Name", value: "field.disease.label" },
      secondaryListText: {
        key: "Status",
        value: "field.is_active ? 'Active':'Inactive' ",
      },
      fields: [
        {
          // multiple: true,
          childField: true,
          autoComplete: true,
          onInputChange: getDiseasesField.handleInputChange,
          items: getDiseasesField.diseases,
          type: "select",
          label: "Diseases",
          name: "disease",
          validation: { required: true },
          // width: 300,
          disableFilter: true,
        },
        {
          childField: true,
          label: "Active",
          name: "is_active",
          type: "boolean",
          width: 300,
        },
      ],
    },
    {
      fieldType: "nested",
      nestedId: 4,
      title: "Allergy",
      name: "allergies",
      primaryListText: { key: "Allergy", value: "field.allergy_type" },
      secondaryListText: {
        key: "Since",
        value: "field.date",
      },
      fields: [
        {
          childField: true,
          isEnum: true,
          type: "select",
          items: allergyTypes,
          label: "Type Of Allergy",
          validation: { required: true },
          name: "allergy_type",
        },
        {
          childField: true,
          label: "Caustive Agent",
          name: "Caustive",
        },
        {
          childField: true,
          label: "Since",
          name: "date",
          type: "date",
        },
        {
          childField: true,
          multipleRecords: true,
          label: "Allergic Reaction",
          name: "allergic_reaction",
        },
        {
          childField: true,
          label: "Note",
          name: "notes",
          multiline: true,
        },
      ],
    },
    {
      fieldType: "nested",
      nestedId: 5,
      title: "Family History",
      name: "family_histories",
      primaryListText: { key: "Disease", value: "field.disease.label" },
      secondaryListText: {
        key: "Relation",
        value: "field.family_members && field.family_members.join()",
      },
      fields: [
        {
          childField: true,
          autoComplete: true,
          onInputChange: getDiseasesField.handleInputChange,
          items: getDiseasesField.diseases,
          type: "select",
          label: "Disease",
          name: "disease",
          // width: 300,
          disableFilter: true,
        },
        {
          childField: true,
          isEnum: true,
          multiple: true,
          items: relations,
          // autoComplete: true,
          // selectAll: true,
          type: "select",
          label: "Relation",
          name: "family_members",
          // width: 300,
        },
      ],
    },
    {
      fieldType: "nested",
      nestedId: 6,
      title: "Special habits",
      name: "special_habits",
      primaryListText: { key: "Type", value: "field.habit_type" },
      secondaryListText: {
        key: "No. of packages",
        value: "field.number_packages",
      },
      fields: [
        {
          childField: true,
          isEnum: true,
          items: specialHabitTypes,
          type: "select",
          label: "Mention",
          name: "habit_type",
          // width: 300,
        },
        watchedFields[2] === "Smoking" && {
          childField: true,
          label: "Number of packages",
          name: "number_packages",
          type: "number",
          // width: 300,
          validation: { required: true, positive: true },
        },
        watchedFields[2] === "Other" && {
          childField: true,
          label: "Details",
          name: "other_details",
          validation: { required: true },
        },
        {
          childField: true,
          label: "Notes",
          name: "notes",
          multiline: true,
        },
      ].filter((item) => item),
    },
    // getConditionalObj(youngPatientDisplayCondition, {
    //   relatedFields: true,
    //   fieldsTitle: "Younger than 12",
    //   displayCondition: youngPatientDisplayCondition,
    //   fields: [
    //     {
    //       childField: true,
    //       isEnum: true,
    //       items: orderBetweenSiblings,
    //       type: "select",
    //       label: "Order between sibilings",
    //       name: "sibling_order",
    //       width: 300,
    //     },
    //     {
    //       fieldType: "nested",
    //       title: "Disease",
    //       condition: [true],
    //       name: "sibling_similar_diseases",
    //       // data: siblingFamilyHistories,
    //       // onAdd: handleAddSiblingFamilyHistory,
    //       // onDelete: (index) =>
    //       //   setSiblingFamilyHistories((oldArr) => removeArrItem(oldArr, index)),
    //       primaryListText: {
    //         key: "Disease",
    //         value: "field.siblingDisease?.label",
    //       },
    //       fields: [
    //         {
    //           childField: true,
    //           autoComplete: true,
    //           onInputChange: handleInputChange,
    //           items: diseases,
    //           type: "select",
    //           label: "Disease",
    //           name: "siblingDisease",
    //           width: 300,
    //         },
    //       ],
    //     },
    //     {
    //       childField: true,
    //       isEnum: true,
    //       items: deliveryTypes,
    //       type: "select",
    //       label: "Type of delivery",
    //       name: "delivery_type",
    //       width: 300,
    //     },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "Obstructed labor",
    //       name: "obstructed_labor",
    //     },
    //     {
    //       childField: true,
    //       isEnum: true,
    //       items: termsOfDelivery,
    //       type: "select",
    //       label: "Term of delivery",
    //       name: "term_delivery",
    //       width: 300,
    //     },
    //     { childField: true, type: "boolean", label: "PROM", name: "prom" },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "Neonatal Sepsis",
    //       name: "neonatal_sepsis",
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         { childField: true, type: "boolean", label: "NICU", name: "nicu" },
    //         {
    //           childField: true,
    //           condition: watchedFields[7],
    //           type: "number",
    //           label: "Days",
    //           name: "nicu_details",
    //           width: 160,
    //           validation: { positive: true },
    //         },
    //       ],
    //     },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "History of jaudince",
    //       name: "jaundice_history",
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         {
    //           childField: true,
    //           type: "boolean",
    //           label: "Maternal Illness",
    //           name: "maternal_illness",
    //         },
    //         {
    //           childField: true,
    //           condition: watchedFields[8],
    //           label: "Mention",
    //           name: "maternal_illness_details",
    //           width: 160,
    //         },
    //       ],
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         {
    //           childField: true,
    //           type: "boolean",
    //           label: "Maternal Drugs",
    //           name: "maternal_drugs",
    //         },
    //         {
    //           childField: true,
    //           condition: watchedFields[9],
    //           label: "Mention",
    //           name: "maternal_drugs_details",
    //           width: 160,
    //         },
    //       ],
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         {
    //           childField: true,
    //           type: "boolean",
    //           label: "Maternal Vaccination",
    //           name: "maternal_vaccination",
    //         },
    //         {
    //           childField: true,
    //           condition: watchedFields[10],
    //           label: "Mention",
    //           name: "maternal_vaccination_details",
    //           width: 160,
    //         },
    //       ],
    //     },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "Folate intake",
    //       name: "folate_intake",
    //     },
    //   ],
    // }),
    // getConditionalObj(adultFemaleDisplayCondition, {
    //   relatedFields: true,
    //   fieldsTitle: "Female between 12 - 50",
    //   displayCondition: adultFemaleDisplayCondition,
    //   fields: [
    //     {
    //       childField: true,
    //       type: "date",
    //       label: "Date of menarche",
    //       name: "date_menarche",
    //       width: 300,
    //     },
    //     {
    //       childField: true,
    //       type: "date",
    //       label: "Date of LMP",
    //       name: "date_lmp",
    //       width: 300,
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         {
    //           childField: true,
    //           label: "Number of days of menses",
    //           name: "num_menses_days",
    //           type: "number",
    //           width: 160,
    //         },
    //         {
    //           childField: true,
    //           label: "Interval between each cycle",
    //           name: "interval_cycles",
    //           type: "number",
    //           width: 160,
    //         },
    //       ],
    //     },
    //     {
    //       twoFieldsInRow: true,
    //       fields: [
    //         {
    //           childField: true,
    //           label: "Amount of menses",
    //           name: "num_tampons_per_day",
    //           type: "number",
    //           width: 160,
    //         },
    //         {
    //           childField: true,
    //           type: "date",
    //           label: "Date of menopause",
    //           name: "date_menopause",
    //           width: 180,
    //         },
    //       ],
    //     },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "Pregnancies",
    //       name: "previous_pregnancy",
    //     },
    //     {
    //       relatedFields: true,
    //       addBorder: false,
    //       displayCondition: watchedFields[11] && watchedFields[11][0],
    //       fields: [
    //         {
    //           twoFieldsInRow: true,
    //           fields: [
    //             {
    //               childField: true,
    //               label: "Number of full term deliveries",
    //               name: "num_full_term_deliveries",
    //               type: "number",
    //               width: 160,
    //             },
    //             {
    //               childField: true,
    //               label: "Number of pre-term deliveries",
    //               name: "num_pre_term_deliveries",
    //               type: "number",
    //               width: 160,
    //             },
    //           ],
    //         },
    //         {
    //           twoFieldsInRow: true,
    //           fields: [
    //             {
    //               childField: true,
    //               label: "Number of abortions",
    //               name: "num_abortions",
    //               type: "number",
    //               width: 160,
    //             },
    //             {
    //               childField: true,
    //               label: "Number of livings",
    //               name: "num_livings",
    //               type: "number",
    //               width: 160,
    //             },
    //           ],
    //         },
    //         {
    //           childField: true,
    //           multipleRecords: true,
    //           label: "Places of previous deliveries",
    //           name: "previous_delivery_place",
    //           width: 300,
    //         },
    //         {
    //           childField: true,
    //           label: "Complications of previous deliveries",
    //           name: "previous_delivery_complications",
    //           width: 300,
    //         },
    //         {
    //           childField: true,
    //           label: "Puperium",
    //           name: "purperium",
    //           width: 300,
    //         },
    //       ],
    //     },
    //     {
    //       childField: true,
    //       type: "boolean",
    //       label: "Contraception",
    //       name: "is_contraceptive",
    //     },
    //     {
    //       childField: true,
    //       type: "date",
    //       condition: watchedFields[12],
    //       label: "Since",
    //       name: "contraception_date",
    //       width: 300,
    //     },
    //     {
    //       childField: true,
    //       isEnum: true,
    //       condition: watchedFields[12],
    //       items: contraceptionTypes,
    //       type: "select",
    //       label: "Type of contraception",
    //       name: "contraception_type",
    //       width: 300,
    //     },
    //   ],
    // }),
  ];

  //list view
  const data = [
    {
      fieldType: "nested",
      label: "Medications",
      data: getPastHistory.data.general_form?.medications,
      primaryListText: { key: "Medication", value: "dataItem.medication" },
      secondaryListText: {
        key: "Status",
        value: "dataItem.is_active ? 'Active':'Inactive' ",
      },
    },
    {
      fieldType: "nested",
      label: "Surgical History",
      data: getPastHistory.data.general_form?.surgeries,
      primaryListText: { key: "Name", value: "dataItem.label" },
      secondaryListText: {
        key: "Date",
        value: "dataItem.date",
      },
    },
    {
      fieldType: "nested",
      label: "Diseases",
      data: getPastHistory.data.general_form?.diseases,
      primaryListText: { key: "Disease", value: "dataItem.disease.label" },
      secondaryListText: {
        key: "Status",
        value: "dataItem.is_active ? 'Active':'Inactive' ",
      },
    },
    {
      fieldType: "nested",
      label: "Allergies",
      data: getPastHistory.data.general_form?.allergies,
      primaryListText: {
        value: "`${dataItem.allergy_type} - Since ${dataItem.date}`",
      },
      secondaryListText: {
        key: "Allergic Reaction",
        value:
          "`${dataItem.allergic_reaction.map(reaction => ` ${reaction}`)}`",
      },
      thirdListText: {
        key: "Notes",
        value: "dataItem.notes",
      },
    },
    {
      fieldType: "nested",
      label: "Family History",
      data: getPastHistory.data.general_form?.family_histories,
      primaryListText: { key: "Disease", value: "dataItem.disease.label" },
      secondaryListText: {
        key: "Relation",
        value: "dataItem.family_members && dataItem.family_members.join()",
      },
    },
    {
      fieldType: "nested",
      label: "Special habits",
      data: getPastHistory.data.general_form?.special_habits,
      primaryListText: { key: "Type", value: "dataItem.habit_type" },
      secondaryListText: {
        key: "No. of packages",
        value: "dataItem.number_packages",
      },
      thirdListText: {
        key: "Notes",
        value: "dataItem.notes",
      },
    },
  ].filter((item) => item.data);

  const youngPatientData = getPastHistory.data.young_patient
    ? [
        {
          label: "Order between siblings",
          data: getPastHistory.data.young_patient?.sibling_order,
        },
        {
          fieldType: "nested",
          label: "Sibling Similar Diseases",
          data:
            getPastHistory.data.young_patient?.sibling_similar_diseases || [],
          primaryListText: { key: "Disease", value: "dataItem.label" },
        },
        {
          label: "Type of delivery",
          data: getPastHistory.data.young_patient?.delivery_type,
        },
        {
          fieldType: "boolean",
          label: "Obstructed labor",
          data: getPastHistory.data.young_patient?.obstructed_labor,
        },
        {
          label: "Term of delivery",
          data: getPastHistory.data.young_patient?.term_delivery,
        },
        {
          fieldType: "boolean",
          label: "PROM",
          data: getPastHistory.data.young_patient?.prom,
        },
        {
          fieldType: "boolean",
          label: "Neonatal Sepsis",
          data: getPastHistory.data.young_patient?.neonatal_sepsis,
        },
        {
          fieldType: "booleanWithDetail",
          label: getPastHistory.data.young_patient?.nicu ? "NICU Days" : "NICU",
          data: getPastHistory.data.young_patient?.nicu,
          detail: getPastHistory.data.young_patient?.nicu_details,
        },
        {
          fieldType: "boolean",
          label: "History of jaudince",
          data: getPastHistory.data.young_patient?.jaundice_history,
        },
        {
          fieldType: "booleanWithDetail",
          label: "Maternal Illness",
          data: getPastHistory.data.young_patient?.maternal_illness,
          detail: getPastHistory.data.young_patient?.maternal_illness_details,
        },
        {
          fieldType: "booleanWithDetail",
          label: "Maternal Drugs",
          data: getPastHistory.data.young_patient?.maternal_drugs,
          detail: getPastHistory.data.young_patient?.maternal_drugs_details,
        },
        {
          fieldType: "booleanWithDetail",
          label: "Maternal Vaccination",
          data: getPastHistory.data.young_patient?.maternal_vaccination,
          detail:
            getPastHistory.data.young_patient?.maternal_vaccination_details,
        },
        {
          fieldType: "boolean",
          label: "Folate intake",
          data: getPastHistory.data.young_patient?.folate_intake,
        },
      ].filter((item) => item.data)
    : []; // empty array to stop reading it as undefind

  const openFemaleRecordHandler = (item) => {
    setFemaleDetailObj(item);
    handleOpenModal();
  };

  const adultFemaleData = [
    getPastHistory.data.adult_female?.length > 0 && {
      label: "Female between 12 - 50",
      fieldType: "element",
      element: (
        <>
          {getPastHistory.data.adult_female?.length > 0 ? (
            <Stack>
              {getPastHistory.data.adult_female.map((item) => (
                <Box
                  key={item.id}
                  onClick={() => openFemaleRecordHandler(item)}
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "10px",
                    p: 1,
                    m: 1,
                    backgroundColor: "#A5D2FF",
                    cursor: "pointer",
                    width: "150px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="primary">{`${
                    item.created_at.split("T")[0]
                  }`}</Typography>
                </Box>
              ))}
            </Stack>
          ) : (
            <Typography>No Female records</Typography>
          )}
        </>
      ),
    },
  ].filter((item) => item);

  const adultFemaleDetailData = [
    { label: "Date of menarche", data: femaleDetailObj?.date_menarche },
    { label: "Date of LMP", data: femaleDetailObj?.date_lmp },
    {
      label: "Number of days of menses",
      data: femaleDetailObj?.num_menses_days,
    },
    {
      label: "Interval between each cycle",
      data: femaleDetailObj?.interval_cycles,
    },
    {
      label: "Number of Meneses Pads/Day",
      data: femaleDetailObj?.num_tampons_per_day,
    },
    {
      label: "Date of menopause",
      data: femaleDetailObj?.date_menopause,
    },
    {
      fieldType: "boolean",
      label: "Contraception",
      data: femaleDetailObj?.is_contraceptive,
    },
    conditionalObj(femaleDetailObj?.is_contraceptive, {
      label: "Since",
      data: femaleDetailObj?.contraception_date,
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.is_contraceptive, {
      label: "Type of contraception",
      data: femaleDetailObj?.contraception_type,
      childMargin: 1,
    }),
    {
      fieldType: "boolean",
      label: "Pregnancies",
      data: femaleDetailObj?.pregnancy !== null,
    },
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of pregnancies",
      data: femaleDetailObj?.pregnancy?.num_pregnancies,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of the Last pregnancy",
      data: femaleDetailObj?.pregnancy?.date_last_pregnancy,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of Living Fetus",
      data: femaleDetailObj?.pregnancy?.num_living_fetus,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of birth of youngest",
      data: femaleDetailObj?.pregnancy?.date_birth_youngest,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of Boys",
      data: femaleDetailObj?.pregnancy?.num_boys,
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of Girls",
      data: femaleDetailObj?.pregnancy?.num_girls,
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of full term deliveries",
      data: femaleDetailObj?.pregnancy?.num_full_term_deliveries,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of the last full term Delivery",
      data: femaleDetailObj?.pregnancy?.date_last_full_term_delivery,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of pre-term deliveries",
      data: femaleDetailObj?.pregnancy?.num_pre_term_deliveries,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of the last pre-term Delivery",
      data: femaleDetailObj?.pregnancy?.date_last_pre_term_delivery,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of post term deliveries",
      data: femaleDetailObj?.pregnancy?.num_post_term_deliveries,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of the last post term Delivery",
      data: femaleDetailObj?.pregnancy?.date_last_post_term_delivery,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Number of abortions",
      data: femaleDetailObj?.pregnancy?.num_abortions,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Date of the last abortion",
      data: femaleDetailObj?.pregnancy?.date_last_abortion,
      childMargin: 1,
      halfRow: true,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Onset of previous delivery",
      data: femaleDetailObj?.pregnancy?.onset_prev_deliveries?.map(
        (item) => item.label
      ),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Mode of previous delivery",
      data: femaleDetailObj?.pregnancy?.mode_prev_deliveries?.map(
        (item) => item.label
      ),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Baby",
      data: femaleDetailObj?.pregnancy?.babies?.map((item) => item.label),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Antepartum Period",
      data: femaleDetailObj?.pregnancy?.antepartums?.map((item) => item.label),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Complications of previous deliveries",
      data: femaleDetailObj?.pregnancy?.complications?.map(
        (item) => item.label
      ),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Places of previous deliveries",
      data: femaleDetailObj?.pregnancy?.delivery_places?.map(
        (item) => item.label
      ),
      childMargin: 1,
    }),
    conditionalObj(femaleDetailObj?.pregnancy?.hasOwnProperty("id"), {
      label: "Puperium",
      data: femaleDetailObj?.pregnancy?.purperiums?.map((item) => item.label),
      childMargin: 1,
    }),
  ].filter((item) => item);

  useEffect(() => {
    // if (youngPatientDisplayCondition)
    //   setListData([...data, ...youngPatientData]);
    // else if (adultFemaleDisplayCondition)
    //   setListData([...data, ...adultFemaleData]);
    // else setListData(data);

    if (!getPastHistory.data) return; // Prevents running on empty data

    switch (mode) {
      case "female":
        setListData(adultFemaleData);
        break;
      case "young":
        if (getPastHistory.data.young_patient) {
          setListData(youngPatientData);
        } else {
          console.log("No Pediatric History Available");
          setListData([{ label: "No Pediatric History Available", data: "" }]);
        }
        break;
      default:
        setListData(data);
        break;
    }
  }, [getPastHistory.data, mode]);

  console.log("past history details", listData);

  //printing
  const dataToPrint = {
    header: {
      title: mode === "young" ? "Past History (Pediatric)" : "Past History",
    },
    content: listData?.map((item) => {
      const obj = { label: item.label, data: item.data };
      if (item.label === "Medications") {
        obj.data = item.data?.map((el) => ({
          medication: el.medication,
          status: el.is_active ? "active" : "inactive",
        }));
      } else if (item.label === "Diseases") {
        obj.data = item.data?.map((el) => ({
          disease: el.disease?.label,
          status: el.is_active ? "active" : "inactive",
        }));
      } else if (item.label === "Family History") {
        obj.data = item.data?.map((el) => ({
          disease: el.disease?.label,
          family_members: el.family_members?.join(", "),
        }));
      } else if (item.fieldType === "booleanWithDetail") {
        obj.data = item.detail ? item.detail : item.data;
      }

      // else if (item.label === "Family History"){
      //   obj.data =
      // }
      return obj;
    }),
  };

  const adultFemaleDataToPrint = {
    header: {
      title: "Past History (Adult Female)",
    },
    content: adultFemaleDetailData,
  };

  return (
    <>
      <CebForm
        onSubmit={onSubmit}
        viewModeState={[viewMode, setViewMode]}
        fields={chosenFields.filter((f) => f)}
        fieldsToWatch={{
          name: [
            "family_histories_family_members", //0
            "family_histories_disease", //1
            "special_habits_habit_type", //2
            "special_habits_number_packages", //3
            "special_habits_notes", //4
            "siblingSimilarCondition", //5
            "siblingDisease", //6
            "nicu", //7
            "maternal_illness", //8
            "maternal_drugs", //9
            "maternal_vaccination", //10
            "previous_pregnancy", //11
            "is_contraceptive", //12
            "similarCondition", //13
            "specialHabits", //14
            "surgeryLabel", //15
            "surgeryDate", //16
            "num_full_term_deliveries", //17
            "num_pre_term_deliveries", //18
            "num_post_term_deliveries", //19
            "num_abortions", //20
          ],
          defaultValue: [false],
        }}
        getWatchedFields={(fields) => setWatchedFields(fields)}
        listData={listData}
        addInList={true}
        resetState={[resetForm, setResetForm]}
        loading={getPastHistory.loading || addPastHistory.loading}
        requestErrors={addPastHistory.error}
        module={module}
        dataToPrint={mode !== "female" && dataToPrint}
      />
      <Modal open={openModal} handleOpen={handleOpenModal}>
        <div style={{ direction: i18next.language === "en" ? "ltr" : "rtl" }}>
          <CebForm
            modalTitle={`${t("Female between 12 - 50")} (${
              femaleDetailObj?.created_at?.split("T")[0]
            })`}
            handleOpenModal={handleOpenModal}
            // onSubmit={onSubmit}
            viewModeState={[femaleViewMode, setFemaleViewMode]}
            // fields={fields}
            modalList
            listData={adultFemaleDetailData}
            addInList={true}
            module={module}
            dataToPrint={adultFemaleDataToPrint}
          />
        </div>
      </Modal>
    </>
  );
}
